import React from "react";
import styled from "styled-components";
import withLayout from "../../layout";
import {FormattedMessage, injectIntl} from "react-intl";
//COMPONENTS
import SimpleStats from "../../components/UI/SimpleStats";
import Image from "../../components/UI/Image";
//GENERIC STYLE IMPORT
import {Heading, Paragraph} from "../../components/UI/Typography";
import DropBottom from '../../images/case_studies/translated/DropBottom.svg';
import DropUp from '../../images/case_studies/translated/DropUp.svg';
import BKGImg from "../../images/case_studies/translated/Banner-translated.jpg";
import HeroCaseStudy from "../../components/HeroCaseStudy";
import LottieController from "../../components/UI/LottieController";
import {useInView} from "react-intersection-observer";
import DropBg from "../../components/UI/DropBg";
import DropBig1 from '../../images/case_studies/acea/DropBig1.svg';
import DropBig2 from '../../images/case_studies/acea/DropTopLeftM.svg';
import SEO from "../../components/SEO";
import Result2 from '../../images/case_studies/translated/Icona Tachimetro.json';
import Result1 from '../../images/case_studies/translated/30% Graphic.json';
import DropTestimonial from "../../images/case_studies/enel/drop_testimonial.svg";
import {Fade} from 'react-reveal';
import useWindowSize from "../../hooks/useWindowSize";
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage";
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory";
import SectionTitle from "../../components/CaseStudy/SectionTitle";
import Results from "../../components/CaseStudy/Results";
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"

const StatSection = styled.div`
padding-top:32px;
h1{
font-weight: bold;
font-size: 80px;
letter-spacing: 2.9px;
color:#1D0F41;
}
h2{
font-weight: bold;
font-size: 50px;
letter-spacing: 1.82px;
color:#0055B8;
}
p{
    font-weight:bold;
    font-size:18px
    letter-spacing:0.39px;
    color:#0055B8;
}
`

const StatsDiv = styled.div`
margin-top: 32px;
h3{
color:#000000;
font-weight:700;
font-style:normal;
font-size:32px;
margin:0px;
}
p{
    color:#118667;
    font-size:18px;
    font-weight:700;
}
`

const CompanyStats = () => {
    return <StatsDiv>
        <h3>201.101</h3>
        <p style={{color: '#0055B8'}}>
            <FormattedMessage id={'translated.stats_1_msg'}/>
        </p>
    </StatsDiv>
}

const TranslatedCaseStudy = ({intl}) => {
    const [graphView1, inViewG1] = useInView();
    const [graphView2, inViewG2] = useInView();
    const keywords = intl.formatMessage({id: 'translated.meta_keywords'}).split(',') || [];
    const {width: pageWidth} = useWindowSize();

    return (
        <>
            <SEO title={intl.formatMessage({id: 'translated.meta_title'})}
                 description={intl.formatMessage({id: 'translated.meta_description'})} keywords={keywords}
                 lang={intl.locale}/>

            <HeroCaseStudy hasPartner={false} heroImage={'case_studies/translated/Header-translated.png'} title={'translated.title'}
                           stats={<CompanyStats/>} subtitle1={'translated.subtitle'}
                           color={'linear-gradient(90deg, #00336F 0%, #0055B8  100%)'} intl={intl}
                           categories={['portfolio.filters.design_dev', 'portfolio.filters.data_analysis_consulting']}/>
            <div className="section-padding">
                <DropBg src={DropBig1} left={'0%'} zIndex={'-1'} bottom={'-20%'} className="d-none d-lg-block"/>
                <DropBg src={DropUp} bottom={'5%'} left={'25%'} className="d-none d-lg-block"/>
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'01'} text={'translated.overview_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className={"col-md-5 col-12"}>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"translated.client_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"translated.client_1"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className={"col-md-6 col-12 offset-md-1"}>
                                <Heading level={"4"} className="pt-2">
                                    <FormattedMessage id={"translated.client_needs"}/>
                                </Heading>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"translated.client_needs_1"}/>
                                </Paragraph>
                                <StatSection>
                                    <h1>187</h1>
                                    <h2><FormattedMessage id={"translated.clients_sub_1"}/></h2>
                                    <p><FormattedMessage id={"translated.clients_sub_2"}/></p>
                                </StatSection>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <FullWidthImage image={BKGImg} imageHeight={1080} imageWidth={1920}/>
            <div className="section-padding">
                <DropBg src={DropBig2} right={'-10%'} zIndex={'-1'} top={'15%'} className="d-none d-lg-block"/>
                <DropBg src={DropBottom} top={'10%'} right={'10%'} className="d-none d-lg-block"/>
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className="col-md-2 d-none d-md-block">
                                <SectionTitle number={'02'} text={'translated.brief_title'}/>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <Heading level={"2"} margin={'0px'}>
                                    <FormattedMessage id={"translated.service_big_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id="translated.services_txt_1"/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-md-12 d-md-block d-none">
                                <Image filename={'case_studies/translated/Translated-infografica.jpg'} className="img-fluid"
                                       alt={'infographic'}
                                       style={{maxWidth: "700px", display: "block", margin: "80px auto 0"}}/>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'03'} text={'translated.workflow_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row ">
                        <Fade bottom delay={400}>
                            <div className="col-12">
                                <Heading level={"3"}>
                                    <FormattedMessage id={"translated.solutions_big_title"}/>
                                </Heading>
                                <Heading level={"4"}>
                                    <FormattedMessage id={"translated.solutions_title_1"}/>
                                </Heading>
                            </div>
                        </Fade>
                        <Fade bottom delay={600}>
                            <div className="col-md-6  col-sm-12">
                                <Paragraph>
                                    <FormattedMessage id={"translated.solutions_txt_1"}/>
                                </Paragraph>
                                <Paragraph>
                                    <FormattedMessage id={"translated.solutions_txt_1_b"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-md-5 offset-md-1 col-sm-12">
                                <Paragraph>
                                    <FormattedMessage id={"translated.solutions_txt_1_c"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={1000}>
                            <div className="col-md-6 col-sm-12">
                                <Heading level={"4"}>
                                    <FormattedMessage id={"translated.solutions_title_2"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"translated.solutions_txt_2"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={1200}>
                            <div className="col-md-5 col-sm-12 offset-md-1">
                                <Image filename={'case_studies/translated/Translated_schermate.jpg'}
                                       alt={'translated screen'} style={{width: pageWidth < 768 ? '100%' : '180%'}}/>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="section-padding-custom">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade bottom delay={400}>
                            <div className="col-lg-7 col-sm-12 order-last order-lg-first d-md-flex">
                                <div className="testimonial-wrapper">
                                    <img src={DropTestimonial} alt={'testimonial'} className="testimonial-drop"/>
                                    <div className="sentence-container">
                                        <p className="sentence">
                                            <FormattedMessage id={"translated.citation"}/>
                                        </p>
                                        <div className="d-flex align-items-center justify-content-center testimonial">
                                            <div className="testimonial-img">
                                                <Image filename={"testimonial.png"} alt={"testimonial"}
                                                       style={{display: 'block'}}/>
                                            </div>
                                            <div>
                                                <Paragraph italic={true} customColor={'blueHoki'}>
                                                    <FormattedMessage id={"translated.citation_user"}/>
                                                    <br/>
                                                    <FormattedMessage id={"translated.citation_user_desc"}/>
                                                </Paragraph>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-lg-5 col-12 order-first order-lg-last">
                                <Heading level={"4"}>
                                    <FormattedMessage id={"translated.solutions_title_3"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"translated.solutions_txt_3"}/>
                                </Paragraph>
                                <Paragraph>
                                    <FormattedMessage id={"translated.solutions_txt_3_b"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <Results
                subtitle="translated.dark_section_paragraph"
                testimonialQuote="translated.dark_section_quote"
                testimonialImg="agency/marco_ciotoli.jpg"
                testimonialName="translated.dark_section_testimonial_name"
                testimonialRole="translated.dark_section_testimonial_desc"
            >
                <div className="col-12 col-lg-3">
                    <div ref={graphView1}>
                        <LottieController
                            lottieJSON={Result1}
                            inView={inViewG1}
                            height="130px"
                            width="295px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG1}
                            valueFrom={0}
                            valueTo={30}
                            prefix="+"
                            headingStyle={{fontWeight: 500}}
                            measureUnit="%"
                            label="translated.stats_1"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView2}>
                        <LottieController
                            lottieJSON={Result2}
                            inView={inViewG2}
                            height="130px"
                            width="210px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            prefix="x"
                            inView={inViewG2}
                            valueFrom={0}
                            valueTo={3}
                            label="translated.stats_2"
                            headingLevel="1"
                            labelColor="white"
                            headingStyle={{fontWeight: 500}}
                        />
                    </div>
                </div>
            </Results>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="translated.next_success_case_title"
                desc="translated.next_success_case_description"
                link="/case-study/audi/"
                intl={intl}
            />
        </>
    )
}

const customProps = {
    localeKey: "translated", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(TranslatedCaseStudy))
